import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
  TextField,
  SelectField,
  View,
  withAuthenticator,
  Link,
} from '@aws-amplify/ui-react';
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { listNotes } from "./graphql/queries";
import {
  createNote as createNoteMutation,
  deleteNote as deleteNoteMutation,
//  updateNote as updateNoteMutation,
} from "./graphql/mutations";

import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import awsconfig from "./amplifyconfiguration.json";
import { uploadData, getUrl, remove } from 'aws-amplify/storage';
import { I18n } from "aws-amplify/utils";
import { translations } from '@aws-amplify/ui'

I18n.putVocabularies(translations);

Amplify.configure(awsconfig);

const client = generateClient();

const App = ({ signOut }) => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    fetchNotes();
  }, []);

  async function fetchNotes() {
    const apiData = await client.graphql({ query: listNotes });
    const notesFromAPI = apiData.data.listNotes.items;
    await Promise.all(
      notesFromAPI.map(async (note) => {
        if (note.image) {
          const url = await getUrl({ key: note.name });
          note.image = url.url;  
        }
        return note;
      })
    );
    setNotes(notesFromAPI);
  }

  async function createNote(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const image = form.get("image");
    const data = {
      name: form.get("name"),
      description: form.get("description"),
      image: image.name,
      bought: false,
      keep: false,
      color: form.get('color'),
      category: form.get('category')
    };
    if (!!data.image) await uploadData({
      key: data.name,
      data: image
    });
    await client.graphql({
      query: createNoteMutation,
      variables: { input: data },
    });
    fetchNotes();
    event.target.reset();
  }

  async function deleteNote({ id, name }) {
    const newNotes = notes.filter((note) => note.id !== id);
    setNotes(newNotes);
    await remove({ key: name });
    await client.graphql({
      query: deleteNoteMutation,
      variables: { input: { id } },
    });
  }

  return (
    <View className="App">
      <Heading level={1} fontSize={'1.2em'}>かいめも</Heading>
      <View margin="1rem 0">
      {notes.sort( (a,b) => {
        // 1st sort by category (desc order)
        if ( a.category > b.category )
        {
          return -1;
        }
        else if ( a.category < b.category )
        {
          return 1;
        }

        // Then sory by name
        if ( a.name > b.name )
        {
          return 1;
        }
        else if ( a.name < b.name )
        {
          return -1;
        }

        return 0;
      })
      .map((note) => (
        <Flex
          key={note.id || note.name}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{'marginBottom': '0.3em', 'backgroundColor': note.color}}
        >
          <Text as="span" fontSize={'0.6em'}>
            {note.category}
          </Text>
          <Text as="span" fontSize={'1.4em'} fontWeight={600}>
            {note.name}
          </Text>
          <Text as="span">
            {note.description}
          </Text>
          {note.image && (
            <Image
              src={note.image}
              alt={`visual aid for ${notes.name}`}
              style={{ width: 400 }}
            />
          )}
          <Link fontWeight={800}
            fontSize={'1.6em'}
            onClick={() => {
            const message="削除しますか？（キープ機能はまだないので完全に消えます..）";
            const result = window.confirm(message);
            if (result) {
              deleteNote(note);
            }          
            }}>×</Link>
        </Flex>
      ))}
      </View>

      <Heading level={2} fontSize={'1.2em'} marginTop={'1em'}>項目追加</Heading>
      <View as="form" margin="1rem 0" onSubmit={createNote}>
        <Flex direction="row" justifyContent="center">
          <SelectField
            name="color"
            selectedValue="white"
            >
            <option value="white" style={{background: 'white'}}>白</option>
            <option value="salmon" style={{background: 'salmon'}}>赤</option>
            <option value="lightyellow" style={{background: 'lightyellow'}}>黄</option>
            <option value="lightskyblue" style={{background: 'lightskyblue'}}>青</option>
            <option value="springgreen" style={{background: 'springgreen'}}>緑</option>
            <option value="violet" style={{background: 'violet'}}>紫</option>
            <option value="orange" style={{background: 'orange'}}>オレンジ</option>
            <option value="lightgray" style={{background: 'lightgray'}}>グレー</option>
          </SelectField>
          <SelectField
            name="category"
            >
            <option value="食料品">食料品</option>
            <option value="日用品">日用品</option>
            <option value="薬・ケア">薬・ケア</option>
            <option value="文房具">文房具</option>
            <option value="衣服">衣服</option>
            <option value="その他">その他</option>
            <option value="個人A">個人A</option>
            <option value="個人F">個人F</option>
          </SelectField>
          <TextField
            name="name"
            placeholder="品名"
            label="Note Name"
            labelHidden
            variation="default"
            required
          />
          <Button type="submit" variation="primary">
            リストに追加
          </Button>
          </Flex>
          <Heading level={3} fontSize={'1.2em'} marginTop={'1em'}>オプション項目</Heading>
          <Flex direction="row" justifyContent="center">
          <TextField
            name="description"
            placeholder="説明"
            label="Note Description"
            labelHidden
            variation="default"
          />
          <View
            name="image"
            as="input"
            type="file"
            style={{ alignSelf: "end" }}
          />
        </Flex>
      </View>
      <Button onClick={signOut}>ログアウト</Button>
    </View>
  );
};

export default withAuthenticator(App);
